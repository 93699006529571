import { colors, constants, spacing } from '@src/Styles'
import Link from 'next/link'
import { useRouter } from 'next/router'
import SearchIcon from '@mui/icons-material/Search'
import { useTypographyContext } from '@src/Typography'

const ICON_HEIGHT = 24

const OrdersSearchButton = () => {
  const router = useRouter()
  const { scale } = useTypographyContext()

  return (
    <Link href={`/orders/search${router.asPath}`} shallow={true}>
      <button
        css={{
          padding: spacing.normal,
          height: '100%',
          border: `${constants.borderWidths.regular}px solid ${colors.neutrals[50]}`,
          borderRadius: constants.borderRadius.medium,
          background: colors.white[100],
          cursor: 'pointer',
          ':hover': { borderColor: colors.neutrals[400] },
        }}
      >
        <SearchIcon
          sx={{
            fontSize: ICON_HEIGHT * scale,
            color: colors.neutrals[400],
          }}
        />
      </button>
    </Link>
  )
}

export default OrdersSearchButton
