import { msg } from '@lingui/macro'
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck'
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk'
import MopedIcon from '@mui/icons-material/Moped'
import { useWonders } from '@src/Fetch/helpers'
import { useLingui } from '@lingui/react'
import { useTypographyContext } from '@src/Typography'
import { colors, constants, spacing } from '@src/Styles'
import { CMAOrderType } from '@src/types'
import { CsbOrderMetadata } from '@kjt01/greendot-wasm'
import { EmotionJSX } from '@emotion/react/types/jsx-namespace'
import { MessageDescriptor } from '@lingui/core'

const options: {
  icon: (scale: number) => EmotionJSX.Element
  label: MessageDescriptor
  value: 'pickup' | 'delivery' | 'all'
}[] = [
  {
    icon: (scale: number) => (
      <DirectionsWalkIcon css={{ height: 20 * scale }} />
    ),
    label: msg`Pickup`,
    value: 'pickup',
  },
  {
    icon: (scale: number) => <MopedIcon css={{ height: 20 * scale }} />,
    label: msg`Delivery`,
    value: 'delivery',
  },
  {
    icon: (scale: number) => (
      <LibraryAddCheckIcon css={{ height: 20 * scale }} />
    ),
    label: msg`All`,
    value: 'all',
  },
]

const OrdersServiceType = ({
  summary,
  serviceType,
  setServiceType,
}: {
  summary: CsbOrderMetadata
  serviceType: CMAOrderType | 'all'
  setServiceType: (value: CMAOrderType | 'all') => Promise<URLSearchParams>
}) => {
  const { data: restaurant } = useWonders(['restaurant_info'])

  const { i18n } = useLingui()
  const { typography } = useTypographyContext()

  const isPickupEnabled =
    restaurant?.location.pickup.kind === 'pickup_available' ||
    restaurant?.location.pickup.kind === 'available'

  const isDeliveryEnabled =
    restaurant?.location.delivery.kind === 'delivery_available' ||
    restaurant?.location.delivery.kind === 'available'

  if (isPickupEnabled && !isDeliveryEnabled) return null

  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'center',
        padding: spacing.base,
        gap: spacing.normal,
        background: colors.primary2[50],
        color: colors.neutrals[500],
        border: `1px solid ${colors.primary2[100]}`,
        borderRadius: constants.borderRadius.medium,
      }}
    >
      {options.map((option) => (
        <button
          key={option.value}
          css={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingBlock: spacing.small,
            background:
              serviceType === option.value ? colors.white[500] : 'none',
            border: 'none',
            borderRadius: constants.borderRadius.medium,
            cursor: 'pointer',
            ':hover': {
              background: colors.primary2[25],
            },
            ':active': {
              color: colors.white[500],
              background: colors.primary2[500],
            },
          }}
          onClick={() => setServiceType(option.value as CMAOrderType)}
        >
          <div css={{ ...typography.label.large, textTransform: 'uppercase' }}>
            {i18n._(option.label)}
          </div>
          <div css={{ ...typography.title.mediumRegular }}>
            (
            {
              summary[
                `${option.value === 'all' ? 'total' : option.value}_count`
              ]
            }
            )
          </div>
        </button>
      ))}
    </div>
  )
}

export default OrdersServiceType
