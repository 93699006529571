import { spacing } from '@src/Styles'
import { SetStateAction, useEffect } from 'react'
import OrderCard from '@src/Order/OrderCard'
import OrdersEmpty from '@src/Orders/OrdersEmpty'
import { useWonders } from '@src/Fetch/helpers'
import { PAYMENT_TYPES, getOrderRootWid } from '@src/Order/helpers'
import { CMAOrderType } from '@src/types'
import { CsbOrderMetadata } from '@kjt01/greendot-wasm'

const OrdersContent = ({
  search,
  startDate,
  endDate,
  serviceType,
  setIsReady,
  paymentType,
  setSummary,
}: {
  search: string
  startDate: string
  endDate: string
  serviceType: CMAOrderType | 'all'
  setIsReady: React.Dispatch<SetStateAction<boolean>>
  paymentType: 'cash' | 'credit' | 'compensated' | null
  setSummary: React.Dispatch<SetStateAction<CsbOrderMetadata>>
}) => {
  const { data, isLoading } = useWonders([
    `get_location_orders`,
    {
      start_time: startDate,
      end_time: endDate,
      service_type: serviceType === 'all' ? null : serviceType,
      ...(paymentType != null
        ? {
            payment_types: PAYMENT_TYPES[paymentType],
          }
        : {}),
    },
    {
      refreshInterval: 3000,
    },
  ])

  useEffect(() => {
    if (data != null) {
      setSummary(data.metadata)
    }
  }, [data, setSummary])

  const cmaOrders = data?.order_summaries ?? []

  const orders =
    search === ''
      ? cmaOrders
      : cmaOrders.filter(({ order }) => {
          const contactPhoneNumber =
            order.customer_idn_complex.phone_complex?.contact_phone_number

          const phoneNumber =
            order.customer_idn_complex.phone_complex?.phone_number

          return (
            ('000' + order.ticket_number)
              .slice(-3)
              .includes(search.replace('#', '')) ||
            order.ticket_number?.toString().includes(search) === true ||
            (contactPhoneNumber != null
              ? contactPhoneNumber?.includes(search.replaceAll('-', ''))
              : phoneNumber?.includes(search.replaceAll('-', '')))
          )
        })

  useEffect(() => {
    if (!isLoading) {
      setIsReady(true)
    }
  }, [setIsReady, isLoading])

  if (data == null) return null

  return (
    <div css={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {orders.length === 0 ? (
        <div
          css={{
            display: 'flex',
            flex: 1,
            width: '100%',
            alignItems: 'center',
          }}
        >
          <OrdersEmpty />
        </div>
      ) : (
        <div
          css={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(320px, 1fr))',
            gridAutoRows: 'max-content',
            gap: spacing.base,
            paddingBottom: spacing.base,
            width: '100%',
            flex: 1,
          }}
        >
          {orders.map(({ order, firstd_data }) => (
            <OrderCard
              key={getOrderRootWid(order)}
              order={order}
              deliveryStatus={firstd_data}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default OrdersContent
