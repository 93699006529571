import { getTimeZoneDateTime } from '@src/DateTime/helpers'
import { useWonders } from '@src/Fetch/helpers'
import dayjs from 'dayjs'
import { parseAsString, useQueryState } from 'next-usequerystate'

export const BUSINESS_CUTOFF_START = '00:00:00'
export const BUSINESS_CUTOFF_END = '04:00:00'

export const useDateQueryState = () => {
  const { data: restaurant } = useWonders(['restaurant_info'])

  const restoLocalDateTime = getTimeZoneDateTime({
    timestamp: null,
    timeZone: restaurant?.location.time_zone ?? undefined,
  })

  const [restoLocalDate, restoLocalTime] = restoLocalDateTime.split(', ')

  const isRestoLocalTimeWithinBusinessCutoff =
    restoLocalTime >= BUSINESS_CUTOFF_START &&
    restoLocalTime < BUSINESS_CUTOFF_END

  const [date, setDate] = useQueryState(
    'date',
    parseAsString
      .withOptions({ shallow: false })
      .withDefault(
        isRestoLocalTimeWithinBusinessCutoff
          ? dayjs(restoLocalDate).subtract(1, 'day').format('YYYY-MM-DD')
          : dayjs().format('YYYY-MM-DD'),
      ),
  )

  return { date, setDate }
}
